<script>
import Layout from "@/router/layouts/franchise";
import Qty from "@/components/widgets/franchise-qty";
import Shipping from "@/components/widgets/franchise-shipping";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Inventory: "Estoque",
      Restock: "Reposição",
    },
    es: {
      Inventory: "Estoque",
      Restock: "Reposição",
    },
  },
  components: {
    Layout,
    Qty,
    Shipping,
  },
  data() {
    return {
      categories: null,
      products: null,

      item: {
        loading: false,
        category: "",
        product: "",
        quantity: 1,
      },

      cart: {
        items: {
          total: 0,
          list: null,
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: "0.00",
        sale: "0.00",
        shipping: {
          method: "",
          value: "0.00",
        },
        total: "0.00",
      },

      checkout: {
        loading: false,
        errored: false,
        empty: false,
      },

      balance: {
        status: false,
        value: 0,
      },
    };
  },
  methods: {
    getProducts() {
      api.get("franchise/inventory/products").then((response) => {
        if (response.data.status == "success") {
          this.categories = response.data.categories;
          this.products = response.data.products;
        }
      });
    },
    getCart() {
      api.get("franchise/inventory/cart").then((response) => {
        if (response.data.status == "success") {
          this.cart = response.data;
        }
      });
    },
    addProduct() {
      api
        .post("franchise/inventory/cart", {
          id: this.item.product,
          qty: this.item.quantity,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.item.products = response.data.list;
            this.getCart();
          }
        });
    },
    checkoutCart() {
      this.checkout.loading = true;

      api
        .post("franchise/inventory/checkout", {
          balance: this.balance.status,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.getCart();

            this.$router.push(
              "/franchise/inventory/orders/" + response.data.order.id
            );
          } else if (response.data.status == "inventory") {
            this.getCart();

            this.$noty.info(response.data.message);
          } else {
            this.$noty.error(response.data.message);
          }
        })
        .catch((error) => {
          this.checkout.errored = error;
          this.checkout.loading = false;
          this.$noty.error(
            "Não foi possível finalizar sua compra, tente novamente."
          );
        })
        .finally(() => {
          this.checkout.loading = false;
        });
    },
  },
  computed: {
    getSubtotal() {
      return this.cart.subtotal;
    },
    getShipping() {
      return this.cart.shipping.value;
    },
    getTotal() {
      return parseFloat(this.getSubtotal) + parseFloat(this.getShipping);
    },
    getToPay() {
      if (this.balance.status) {
        if (this.balance.value > 0) {
          if (this.balance.value >= this.getTotal) {
            return 0;
          } else {
            return this.getTotal - this.balance.value;
          }
        } else {
          return this.getTotal;
        }
      } else {
        return this.getTotal;
      }
    },
  },
  mounted() {
    this.getCart();
    this.getProducts();
  },
  watch: {
    "item.category": function () {
      this.item.product = "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">
            {{ t("Inventory") }} › {{ t("Restock") }}
          </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <b-form class="p-3" @submit.prevent="addProduct">
              <b-form-group
                id="categories"
                :label="t('Categorias')"
                label-for="categories"
              >
                <select v-model="item.category" class="custom-select mb-2">
                  <option value="">Selecione uma categoria</option>
                  <option
                    v-for="(c, index) in categories"
                    :key="index"
                    :value="index"
                  >
                    {{ c }}
                  </option>
                </select>
              </b-form-group>
              <b-form-group
                v-if="item.category"
                id="products"
                :label="t('Produtos')"
                label-for="products"
              >
                <select v-model="item.product" class="custom-select mb-2">
                  <option value="">Selecione um produto</option>
                  <option
                    v-for="(p, index) in products[item.category]"
                    :key="index"
                    :value="p.id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </b-form-group>
              <b-form-group
                v-if="item.product"
                id="quantity"
                :label="t('Quantidade')"
                label-for="quantity"
              >
                <b-input-group>
                  <b-form-input
                    id="quantity"
                    v-model="item.quantity"
                    type="number"
                    min="1"
                    autocomplete="off"
                  ></b-form-input>
                  <span
                    class="btn btn-light border-1 rounded-0"
                    v-on:click="item.quantity--"
                    ><i class="bx bx-minus-circle align-middle text-dark"></i
                  ></span>
                  <span
                    class="btn btn-default border-1"
                    style="
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                    v-on:click="item.quantity++"
                    ><i class="bx bx-plus-circle align-middle text-white"></i
                  ></span>
                </b-input-group>
              </b-form-group>
              <div
                v-if="item.category && item.product"
                class="d-flex bg-soft-light rounded p-3"
              >
                <div class="align-self-center">
                  <img
                    v-if="products[item.category][item.product].image"
                    style="width: 80px; height: 80px; object-fit: cover"
                    class="rounded"
                    :src="products[item.category][item.product].image"
                  />
                </div>
                <div class="align-self-center flex-fill ml-3">
                  {{ products[item.category][item.product].name }}
                  <h5 class="m-0">
                    {{ products[item.category][item.product].price | currency }}
                  </h5>
                </div>
              </div>
              <div class="mt-4 text-right">
                <b-button
                  :disabled="this.item.loading == true"
                  type="submit"
                  variant="default"
                >
                  {{ t("Adicionar") }}
                  <b-spinner
                    v-if="item.loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <div v-if="cart.items.total == 0" class="text-center">
                Nenhum produto em seu carrinho.
              </div>
              <table v-else class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th class="border-0" colspan="2">Produto</th>
                    <th style="width: 1%" class="border-0 text-center">Qtd</th>
                    <th style="width: 1%" class="border-0 text-center">
                      Preço
                    </th>
                    <th style="width: 1%" class="border-0 text-center">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody v-if="cart.items.total > 0">
                  <tr v-for="(item, id) in cart.items.list" :key="id">
                    <td>
                      <img
                        v-if="item.image"
                        style="width: 50px; height: 50px; object-fit: cover"
                        class=""
                        :src="item.image"
                      />
                    </td>
                    <td class="font-weight-medium">
                      {{ item.name }}
                      <span
                        v-if="item.inventory == 0"
                        class="font-weight-normal font-size-12 text-danger text-uppercase"
                      >
                        <br />este produto não está mais disponível
                      </span>
                    </td>
                    <td class="text-center">
                      <Qty :id="id" :quantity="item.quantity"></Qty>
                    </td>
                    <td class="text-center">{{ item.price | currency }}</td>
                    <td class="text-center">{{ item.subtotal | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="cart.items.total > 0">
          <div class="card">
            <div class="card-body">
              <h5 class="mb-3">Prazo de Entrega</h5>
              <Shipping></Shipping>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="mb-3">Resumo do Pedido</h5>
              <div class="d-flex mb-2">
                <div class="flex-fill">
                  <span v-if="cart.items.total <= 1">
                    {{ cart.items.total }} produto
                  </span>
                  <span v-else-if="cart.items.total > 1">
                    {{ cart.items.total }} produtos
                  </span>
                </div>
                <div class="flex-fill text-right">
                  {{ getSubtotal | currency }}
                </div>
              </div>
              <div class="d-flex mb-2 text-muted">
                <div class="flex-fill">Frete</div>
                <div class="flex-fill text-right">
                  <div v-if="cart.shipping.method === ''">-</div>
                  <div v-else>
                    {{ cart.shipping.value | currency }}
                  </div>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="flex-fill">
                  <h6 class="mb-0">Total</h6>
                </div>
                <div class="flex-fill text-right">
                  <h6 class="mb-0">{{ getTotal | currency }}</h6>
                </div>
              </div>
              <button
                v-on:click="checkoutCart()"
                class="btn btn-default btn-block font-weight-medium text-uppercase py-2"
                :disabled="checkout.loading"
              >
                <i class="bx bx-basket font-size-24 align-middle pb-1"></i>
                Finalizar Compra
                <b-spinner
                  v-if="checkout.loading"
                  small
                  class="align-middle"
                  variant="white"
                  role="status"
                ></b-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
